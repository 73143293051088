import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import api from "../api/Axios";
import { AuthContext } from "../context/AuthContext";
import DynamicReactQuill, { modules } from "../module/QuillModules";
import "./CreatePost.css";
import "quill/dist/quill.snow.css";

function EditPost() {
  const { id, category } = useParams();
  const [post, setPost] = useState(null); // 초기값을 null로 설정
  const [file, setFile] = useState(null);
  const [theme, setTheme] = React.useState("dark");
  const { userInfo } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await api.get(`/posts/category/${category}/${id}`);
        setPost(response.data);
      } catch (error) {
        console.error("게시글 불러오기 실패:", error);
      }
    };

    fetchPost();
  }, [id, category]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPost((prevPost) => ({ ...prevPost, [name]: value }));
  };

  const handleContentChange = (value) => {
    setPost((prevPost) => ({ ...prevPost, content: value }));
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleThemeChange = () => {
    setTheme((prev) => (prev === "light" ? "dark" : "light")); // 테마 전환
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!userInfo || userInfo.username !== post.author) {
      alert("수정 권한이 없습니다.");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("post", new Blob([JSON.stringify(post)], { type: "application/json" }));
      if (file) {
        formData.append("file", file);
      }

      await api.put(`/posts/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "Content-Type": "multipart/form-data",
        },
      });

      alert("게시글이 수정되었습니다.");
      navigate(`/category/${post.category}/${id}`);
    } catch (error) {
      console.error("게시글 수정 중 오류 발생:", error);
      alert("게시글 수정에 실패했습니다.");
    }
  };

  // post가 null일 경우 로딩 메시지 표시
  if (!post) {
    return <p>로딩 중...</p>;
  }

  return (
    <div style={{ maxWidth: "600px", margin: "50px auto" }}>
      <h2>게시글 수정</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="title"
          value={post.title || ""}
          onChange={handleInputChange}
          placeholder="제목"
          required
          style={{ width: "100%", padding: "10px", margin: "10px 0" }}
        />
        <button onClick={handleThemeChange}>
        {theme === "light" ? "다크 모드로 전환" : "라이트 모드로 전환"}
      </button>
        <DynamicReactQuill
          value={post.content || ""}
          onChange={handleContentChange}
          modules={modules}
          theme={theme}
          placeholder="내용을 입력하세요..."
          className="react-quill-container"
        />
        <input
          type="file"
          onChange={handleFileChange}
          style={{ margin: "10px 0" }}
        />
        <button
          type="submit"
          style={{
            width: "100%",
            padding: "10px",
            margin: "10px 0",
            backgroundColor: "#007bff",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          게시글 수정
        </button>
      </form>
    </div>
  );
}

export default EditPost;
