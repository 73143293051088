import React, { createContext, useState, useEffect } from "react";
import api from "../api/Axios";
import { logout } from "../api/AuthApi";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [accessToken, setAccessToken] = useState(localStorage.getItem("accessToken") || null);

  const updateAccessToken = (newAccessToken) => {
    localStorage.setItem("accessToken", newAccessToken);
    setAccessToken(newAccessToken);
  };

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const storedUser = localStorage.getItem("userInfo");

    if (accessToken && storedUser) {
      api
        .get("/auth/validate-token", { headers: { Authorization: `Bearer ${accessToken}` } })
        .then(() => {
          setUserInfo(JSON.parse(storedUser));
          setIsLoggedIn(true);
        })
        .catch((error) => {
          console.error("Token validation failed:", error);
  
          // 401이 발생한 경우에만 로그아웃 처리
          if (error.response && error.response.status === 401) {
            handleLogout();
          }
        });
    } else {
      setIsLoggedIn(false);
    }
    
    window.addEventListener("beforeunload", handleWindowClose);
    window.addEventListener("unload", handleWindowClose);

    return () => {
      window.removeEventListener("beforeunload", handleWindowClose);
      window.removeEventListener("unload", handleWindowClose);
  };
  }, []);

  const handleLogin = (user, accessToken) => {
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("userInfo", JSON.stringify(user));
    setUserInfo(user);
    setIsLoggedIn(true);
  };

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error("Logout API call failed:", error);
    } finally {
      localStorage.clear();
      setUserInfo(null);
      setIsLoggedIn(false);
    }
  };

  const handleWindowClose = () => {
    const token = localStorage.getItem("accessToken");
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  
    if (token && userInfo?.username) {
      navigator.sendBeacon("https://eb.hongfolio.shop/api/auth/logout", JSON.stringify({ 
        username: userInfo.username 
      }));
    }
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, userInfo, handleLogin, handleLogout, updateAccessToken }}>
      {children}
    </AuthContext.Provider>
  );
};
