import React, { Suspense } from "react";
import "quill/dist/quill.snow.css";
import hljs from "highlight.js";
import "highlight.js/styles/monokai.min.css";
import api from "../api/Axios";

// 이미지 업로드 핸들러
export const imageHandler = function () {
  const input = document.createElement("input");
  input.setAttribute("type", "file");
  input.setAttribute("accept", "image/*");
  input.click();

  input.onchange = async () => {
    const file = input.files[0];
    const formData = new FormData();
    formData.append("file", file);

    try {
      const accessToken = localStorage.getItem("accessToken");
      if (!accessToken) {
        console.error("No token found");
        return;
      }

      const response = await api.post(
        "https://eb.hongfolio.shop/api/posts/upload",
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data", // 멀티파트 형식
          },
        }
      );
  
      const data = response.data;

      if (data.imageUrl) {
        const range = this.quill.getSelection();
        this.quill.insertEmbed(range.index, "image", data.imageUrl);
      }
    } catch (error) {
      console.error("Image upload failed:", error);
    }
  };
};

// highlight.js 설정 (자동 언어 감지 활성화)
hljs.configure({
  languages: ["javascript", "python", "java", "html", "css"], // 지원할 언어 명시
});

// 글로벌 객체로 hljs 등록
window.hljs = hljs;

// React.lazy로 동적 로드
const ReactQuill = React.lazy(() => import("react-quill-new"));

// Quill 모듈 설정
export const modules = {
  syntax: {
    highlight: (text) => hljs.highlightAuto(text).value, // 자동 하이라이팅
  },
  toolbar: {
    container: [
      [{ header: [1, 2, 3, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["code-block"],
      ["image"],
    ],
    handlers: {
      image: imageHandler,
    },
  },
};

// 테마 선택을 위한 설정
export const themes = {
  light: "https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.7.0/styles/github.min.css",
  dark: "https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.7.0/styles/monokai.min.css",
};

// Quill 컴포넌트 래핑 및 export
export default function DynamicReactQuill({ theme, ...props }) {
  React.useEffect(() => {
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = themes[theme] || themes.dark;
    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    };
  }, [theme]);

  return (
    <Suspense fallback={<p>Loading...</p>}>
      <ReactQuill {...props} />
    </Suspense>
  );
}
