import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import api from "../api/Axios";
import { AuthContext } from "../context/AuthContext";
import "quill/dist/quill.snow.css";
import DynamicReactQuill, { modules } from "../module/QuillModules";
import "./CreatePost.css";

function CreatePost() {
  const location = useLocation();
  const category = location.state?.category || "default";
  const subCategory = location.state?.subCategory || "default";
  const [title, setTitle] = useState(localStorage.getItem("postTitle") || "");
  const [content, setContent] = useState(localStorage.getItem("postContent") || "");
  const [file, setFile] = useState(null);
  const [theme, setTheme] = React.useState("dark");
  const { isLoggedIn, userInfo } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isLoggedIn) {
      alert("로그인 후 작성 가능합니다.");
      navigate("/login");
    }
  }, [isLoggedIn, navigate]);

  useEffect(() => {
    localStorage.setItem("postTitle", title);
    localStorage.setItem("postContent", content);
  }, [title, content]);

  const handleTitleChange = (e) => setTitle(e.target.value);
  const handleContentChange = (value) => setContent(value);
  const handleFileChange = (e) => setFile(e.target.files[0]);
  const handleThemeChange = () => {
    setTheme((prev) => (prev === "light" ? "dark" : "light")); // 테마 전환
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const accessToken = localStorage.getItem("accessToken");
      if (!accessToken) {
        alert("로그인이 필요합니다.");
        navigate("/login");
        return;
      }

      const formData = new FormData();
      formData.append("title", title);
      formData.append("content", content);
      formData.append("author", userInfo.username);
      formData.append("category", category);
      formData.append("subCategory", subCategory);
      if (file) {
        formData.append("file", file);
      }

      const response = await api.post("/posts", formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 201) {
        alert("게시글이 성공적으로 작성되었습니다!");
        localStorage.removeItem("postTitle");
        localStorage.removeItem("postContent");
        navigate("/");
      }
    } catch (error) {
      console.error("게시글 작성 중 오류 발생:", error);
      alert("게시글 작성에 실패했습니다.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={{ maxWidth: "600px", margin: "50px auto" }}>
      <h2>게시글 작성</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="제목"
          value={title}
          onChange={handleTitleChange}
          required
          style={{ width: "100%", padding: "10px", margin: "10px 0" }}
        />
        <button onClick={handleThemeChange}>
        {theme === "light" ? "다크 모드로 전환" : "라이트 모드로 전환"}
      </button>
        <DynamicReactQuill
        value={content}
        onChange={handleContentChange}
        modules={modules}
        theme={theme} 
        placeholder="내용을 입력하세요..."
        className="react-quill-container"
      />
        <input
          type="file"
          onChange={handleFileChange}
          style={{ margin: "10px 0" }}
        />
        <button
          type="submit"
          disabled={isLoading}
          style={{
            width: "100%",
            padding: "10px",
            margin: "10px 0",
            backgroundColor: isLoading ? "#cccccc" : "#007bff",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            cursor: isLoading ? "not-allowed" : "pointer",
          }}
        >
          {isLoading ? "게시글 작성 중..." : "게시글 작성"}
        </button>
      </form>
    </div>
  );
}

export default CreatePost;
