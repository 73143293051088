import axios from "axios";
import { logout } from "./AuthApi";

const api = axios.create({
  baseURL: "https://eb.hongfolio.shop/api", // API 기본 URL
  headers: { "Content-Type": "application/json" },
  withCredentials: true, // 쿠키 포함 설정 추가
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        // Refresh Token으로 Access Token 갱신
        const refreshResponse = await api.post("/auth/refresh-token");
        if(refreshResponse.data.accessToken) {
          const newAccessToken = refreshResponse.data.accessToken;

          localStorage.setItem("accessToken", newAccessToken);
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
  
          return api(originalRequest);
        }
      } catch (refreshError) {
        if (refreshError.response && refreshError.response.status === 401) {
          console.warn("Refresh token expired, logging out...");
          await logout();
          window.location.href = "/login";
        }
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);


export default api;
