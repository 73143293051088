import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import api from "../api/Axios";
import hljs from "highlight.js";
import { AuthContext } from "../context/AuthContext";
import "highlight.js/styles/monokai.min.css";
import "./PostDetail.css";

function PostDetail() {
  const { id, category } = useParams();
  const [post, setPost] = useState(null);
  const { userInfo } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await api.get(`/posts/category/${category}/${id}`);
        setPost(response.data);
      } catch (error) {
        console.error("게시글 불러오기 실패:", error);
      }
    };

    fetchPost();
  }, [id, category]);

  useEffect(() => {
    if (post) {
      // Quill 코드 블록 병합 처리
      const container = document.createElement("div");
      container.innerHTML = post.content;
  
      // Quill 코드 블록 처리
      container.querySelectorAll(".ql-code-block-container").forEach((blockContainer) => {
        const codeLines = Array.from(blockContainer.querySelectorAll(".ql-code-block"));
        const codeContent = codeLines.map((line) => line.textContent).join("\n");
  
        // 병합된 코드 블록을 새로운 <pre><code>로 교체
        const pre = document.createElement("pre");
        const code = document.createElement("code");
  
        // highlight.js로 자동 언어 감지 및 하이라이팅
        const highlightedCode = hljs.highlightAuto(codeContent).value;
        code.innerHTML = highlightedCode;
  
        pre.appendChild(code);
        blockContainer.replaceWith(pre);
      });
  
      // 병합된 HTML을 상태로 업데이트
      setPost((prev) => ({ ...prev, content: container.innerHTML }));
  
      // highlight.js 스타일 적용 강제
      document.querySelectorAll("pre code").forEach((block) => {
        block.classList.add("hljs"); // hljs 클래스 추가
  
        // 불필요한 요소 완전 제거
        const parentNode = block.parentNode;
        if (parentNode && parentNode.querySelector("select")) {
          parentNode.querySelector("select").remove(); // 언어 선택 박스 제거
        }
      });
    }
  }, [post]);

  const handleDelete = async () => {
    if (!window.confirm("정말 삭제하시겠습니까?")) return;

    try {
      await api.delete(`/posts/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      alert("게시글이 삭제되었습니다.");
      navigate("/");
    } catch (error) {
      console.error("게시글 삭제 중 오류 발생:", error);
      alert("게시글 삭제에 실패했습니다.");
    }
  };

  const handleEdit = () => {
    navigate(`/edit/${category}/${id}`);
  };

  if (!post) {
    return <p>로딩 중...</p>;
  }

  return (
    <div style={{ maxWidth: "800px", margin: "50px auto", padding: "20px", border: "1px solid #ddd", borderRadius: "8px", backgroundColor: "#f9f9f9" }}>
      <h2 style={{ borderBottom: "2px solid #ddd", paddingBottom: "10px" }}>{post.title}</h2>
      <div style={{ marginTop: "20px" }}>
        <div
          style={{ whiteSpace: "pre-line" }}
          dangerouslySetInnerHTML={{ __html: post.content }}
        ></div>
        {post.imageUrl && (
          <div style={{ marginTop: "20px", textAlign: "center" }}>
            <img src={post.imageUrl} alt="첨부 이미지" style={{ maxWidth: "100%", borderRadius: "8px" }} />
          </div>
        )}
      </div>
      <p style={{ marginTop: "20px", color: "#666" }}>작성자: <strong>{post.author}</strong></p>
      {userInfo && userInfo.username === post.author && (
        <div style={{ marginTop: "20px", display: "flex", gap: "10px" }}>
          <button
            onClick={handleEdit}
            style={{ padding: "10px 20px", border: "none", backgroundColor: "#007bff", color: "#fff", borderRadius: "5px", cursor: "pointer" }}
          >
            게시글 수정
          </button>
          <button
            onClick={handleDelete}
            style={{ padding: "10px 20px", border: "none", backgroundColor: "#dc3545", color: "#fff", borderRadius: "5px", cursor: "pointer" }}
          >
            게시글 삭제
          </button>
        </div>
      )}
    </div>
  );
}

export default PostDetail;